import React from "react";
import {
    Heading,
    Button,
    Text,
    Box,
    Flex,
    Image,
    Stack,
    Container,
    Divider,
    VStack,
    HStack,
    Link,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from "@chakra-ui/react";
//Images
import reportImg from "../assets/report-diagram.svg";
import reportImgMobile from "../assets/report-diagram-mobile.svg";
import compImg from "../assets/handsonlaptop.jpg";
import chooseImg from "../assets/ladies-laughing-laptop.webp";
import logoBlack from "../assets/logo-black.svg";
import theReportHeadingMobile from "../assets/The-Report-heading-mobile.svg";
import theProblemHeadingMobile from "../assets/The-Problem-heading-mobile.svg";
import theReportHeading from "../assets/The-Report-heading.svg";
import theProblemHeading from "../assets/The-Problem-heading.svg";
import linkedInIcon from "../assets/LinkedIn-icon.svg";

export default function Home() {
    return (
        <div>
            <header className="header">
                <Flex
                    direction="column"
                    align={{ base: "center", lg: "end" }}
                    justify="center"
                    w="100vw"
                    pt={{ base: "20vh", sm: "30vh", lg: "15vw" }}
                >
                    <Heading
                        as="h1"
                        w={{ base: "100%", sm: "80%", lg: "60vw" }}
                        fontSize={{ base: "38px", lg: "50px" }}
                        textAlign={{ base: "center", lg: "left" }}
                        pl={{ base: "0%", lg: "23vw" }}
                    >
                        Integrated competency reports, in seconds.
                    </Heading>
                    <Text
                        w={{ base: "80%", lg: "50vw" }}
                        fontSize={{ base: "16px", lg: "20px" }}
                        textAlign={{ base: "center", lg: "left" }}
                        pl={{ base: "0%", lg: "12vw" }}
                    >
                        Use a fraction of time and capital to compile all
                        employee assessments into a single report with just a
                        click.
                    </Text>
                    <Flex
                        w={{ base: "80%", lg: "50vw" }}
                        pl={{ base: "0%", lg: "12vw" }}
                        justify={{ base: "center", lg: "start" }}
                    >
                        <Button
                            bg={"#F8641E"}
                            color="white"
                            fontWeight="500"
                            _hover={{ bg: "rgba(248, 100, 30, 0.7)" }}
                        >
                            Book an appointment
                        </Button>
                    </Flex>
                </Flex>
            </header>
            <Container position="relative" minWidth={"70vw"}>
                <Image
                    position="absolute"
                    top="-16%"
                    left="0"
                    display={{ base: "block", lg: "none" }}
                    alt="The Report Heading"
                    src={theReportHeadingMobile}
                ></Image>
                <Image
                    position="absolute"
                    top="-50%"
                    left="0"
                    display={{ base: "none", lg: "block" }}
                    alt="The Report Heading"
                    src={theReportHeading}
                ></Image>
                <Heading
                    as="h3"
                    textAlign="center"
                    my={{ base: "1em", lg: "3rem" }}
                    px={"2rem"}
                >
                    Your recipe is the secret sauce.
                </Heading>
                <Text
                    fontSize={{ base: "18px", lg: "20px" }}
                    textAlign="center"
                    my={"4rem"}
                    px={"2rem"}
                >
                    {" "}
                    Mix it up - employee performance indicators plus 360
                    assessment measurements plus psychometric assessment scores,
                    all in one report. Whether its for recruitment or
                    development, make use of our extensive experience in
                    generating thousands of integrated reports for international
                    organisations.
                    {/* Whether its for recruitment or development, make use of our
                    extensive experience in generating thousands of integrated
                    reports for international organisations. */}
                </Text>
            </Container>
            <section className="reportSection">
                <Image
                    src={reportImg}
                    className="reportImg"
                    alt="Integrated Report"
                    display={{ base: "none", lg: "block" }}
                />
                <Image
                    src={reportImgMobile}
                    className="reportImgMobile"
                    alt="Integrated Report"
                    display={{ base: "block", lg: "none" }}
                />
            </section>
            <Flex
                direction="column"
                w={{ base: "100%", lg: "100vw" }}
                bg={"#296968"}
                color={"white"}
                m={{ base: "0", lg: "0" }}
                p={{ base: "2.5em", lg: "10em" }}
            >
                {/* <Heading as="h3">Your recipe is the secret sauce.</Heading> */}
                <Heading as="h3">
                    Custom frameworks suited for any integrated report.
                </Heading>
                <Text>
                    You have various bits and pieces of information for your
                    talented resources scattered all over the place. Business
                    stakeholders want meaningful representation of a person, but
                    for differing decision making events, and at ad-hoc times.
                    And then they may want to use that information for
                    comparison purposes.Those pieces of information may need to
                    simply be represented as-is on a report, but without the
                    time and effort of looking it up each time.  Some pieces are
                    best represented when used to derive useful measures
                    together with other pieces of information.  And of course
                    some information offers its best value when displayed next
                    to comparison data.
                </Text>
                <Text fontSize="md">
                    Any figure of measurement is better when dressed with
                    relevant context-based explanation that is useful to the
                    intended reader.The synthesis of measurements plus the
                    text-based explanations, and in context of standard measures
                    is unique to your organsation and allows you to create a
                    consistent and transparent representation of human resources
                    specifically aligned to your organisation.
                </Text>
            </Flex>

            {/* HR Data Section */}
            <Flex
                direction={{ base: "column", lg: "row" }}
                w="100vw"
                p={{ base: "0", lg: "4em" }}
            >
                <Image
                    src={compImg}
                    alt="computer"
                    style={{ objectFit: "contain" }}
                    display={{ base: "block", lg: "none" }}
                />
                <Box
                    w={{ base: "100%", lg: "45%" }}
                    p={{ base: "2.5em", lg: "3em" }}
                >
                    <Heading as="h3" size="lg">
                        Make your human resource data work for you, easily.
                    </Heading>
                    <Text fontSize="md">
                        Simply upload spreadsheets or indvidual reports once and
                        get the ease of generating multiple reports from one
                        single place.
                    </Text>

                    <Text fontSize="md">
                        Let us design those reports to ensure accurate and
                        consistent measures together with appropriate text
                        explanations so that you can be sure business
                        stakeholders are getting exactly what they need, when
                        they need it.
                    </Text>

                    <Text fontSize="md">
                        We can even build integrations to where your data stores
                        are today, so your data is readily on tap for reporting.
                    </Text>
                </Box>

                <Image
                    w="55%"
                    src={compImg}
                    alt="computer"
                    style={{ objectFit: "contain" }}
                    display={{ base: "none", lg: "block" }}
                />
            </Flex>

            {/* Why Choose Us - Section */}
            <Flex
                direction={{ base: "column", lg: "row" }}
                w="100vw"
                bg={"#1b4748"}
                color="white"
            >
                <Image
                    w={{ base: "100%", lg: "55%" }}
                    src={chooseImg}
                    alt="Ladies smiling and looking at their laptops"
                    style={{ objectFit: "cover" }}
                />

                <Box
                    w={{ base: "100%", lg: "45%" }}
                    p={{ base: "4em", lg: "5.2em" }}
                >
                    <Heading as="h3" size="lg">
                        Why choose us
                    </Heading>
                    <Text fontSize="md">
                        Having built systems that generated thousands of
                        integrated reports for international clients, spanning
                        many continents and across multiple languages, we
                        understand the need for generating quality reports for
                        business stakeholders.
                    </Text>
                    <Heading as="h5" size="sm">
                        Talent management data becomes an enabler
                    </Heading>

                    <Text fontSize="md">
                        Following an employee from their recruitment to their
                        most senior promotion, data is a natural consequence of
                        attracting and retaining your talent. But unless that
                        data is available in a timely, consistent and human
                        readable format, talent management becomes a recurring
                        cycle of hit and miss.
                    </Text>
                </Box>
            </Flex>

            {/* The Problem - Section */}
            <section className="colSection">
                <Image
                    position="absolute"
                    top="1%"
                    left="0"
                    display={{ base: "block", lg: "none" }}
                    alt="The Problem Heading"
                    src={theProblemHeadingMobile}
                ></Image>
                <Image
                    position="absolute"
                    top="1%"
                    left="0"
                    display={{ base: "none", lg: "block" }}
                    alt="The Problem Heading"
                    src={theProblemHeading}
                ></Image>

                <Accordion>
                    <AccordionItem py="2.5em">
                        <AccordionButton>
                            <Box as="span" flex="1" textAlign="left">
                                <Heading as="h5" size="md">
                                    What you do
                                </Heading>
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>

                        <AccordionPanel pb={4}>
                            As part of the recruitment and development of human
                            talent you make use of psychometric, 360 and
                            performance assessments. From recruitment to
                            onboarding, promotion to succession planning -
                            business stakeholders require science-based reports
                            to assist their decision making and planning
                            efforts. As the journey of an individual progresses
                            through an organisation, their history grows, their
                            context changes and so their potential evolves.
                            Leveraging off known psychomtric assessment scores,
                            and adding an accumulating amount of job-specific
                            measurements, you are required to apply a consistent
                            framework to reports that stakeholders can
                            understand and apply appropriately.
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem py="2.5em">
                        <AccordionButton>
                            <Box as="span" flex="1" textAlign="left">
                                <Heading as="h5" size="md">
                                    Your challenges
                                </Heading>
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>

                        <AccordionPanel pb={4}>
                            Various assessments are sourced from various parties
                            inside and outside the organisation. Storing those
                            reports, extracting the various scores, collating
                            the relevant scores and presenting consistent
                            human-readable reports necessitates constant
                            monitoring and oversight by registered
                            professionals. Relegating qualified professionals to
                            rudimentary admin tasks means more time is spent on
                            generating reports than interpreting and advising on
                            the contents. Add to that the benefit of trending an
                            individuals journey against relevant peers, deepens
                            the emphasis on rinse-and-repeat activities before
                            business stakeholders can get the most valueble
                            reports into their decision process.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem py="2.5em">
                        <AccordionButton>
                            <Box as="span" flex="1" textAlign="left">
                                <Heading as="h5" size="md">
                                    Your sources
                                </Heading>
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>

                        <AccordionPanel pb={4}>
                            Custom performance measurements, 9 box grid, 360
                            assessments, EQi, MBTI, HPI, HDS, MVPI, MCPA, CPP,
                            Big five, DISC, Ravens, 16pf etc. These typically
                            are referenced from various spreadsheets stored in
                            different places within an organisaton. The
                            spreadsheets in turn contain links to documents
                            stored within an organisation as well as on thrid
                            party service providers and often only carry implied
                            access control if you know the document link and/or
                            you have access to the third party site.
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </section>

            {/* Schedule An Appointment Box */}
            <Container minWidth="70vw" p={{ base: "2em", lg: "0em" }}>
                <Box
                    p={{ base: "2em", lg: "4em" }}
                    bg={"#B3D2D1"}
                    textAlign="center"
                >
                    <Heading as="h3" size="lg">
                        Schedule a virtual appointment today.
                    </Heading>
                    <Text fontSize="md">
                        We would love to hear about your talent management
                        journey. Book a chat with us and lets see if our
                        extensive experience and possibly a demonstration can
                        help you in any way.
                    </Text>
                    <Button
                        bg={"#F8641E"}
                        shadow="1px 2px 5px 1px rgba(0, 0, 0, 0.25)"
                        color="white"
                        _hover={{ bg: "rgba(248, 100, 30, 0.7)" }}
                    >
                        Book an appointment
                    </Button>
                </Box>
            </Container>

            {/* Footer */}
            <Box
                bg={"#1B4748"}
                as="footer"
                p={{ base: "2em 1.5em 2em 1.5em", lg: "4em 3em 1em 3em" }}
            >
                <Stack
                    direction={{ base: "column", lg: "row" }}
                    spacing={3}
                    align="center"
                    p={{ base: "2em", lg: "0" }}
                >
                    {/* Contact Us */}
                    <VStack
                        align="space-between"
                        w={{ base: "100%", lg: "35%" }}
                        m={{
                            base: "1em 0em 1em 0em",
                            lg: "2.5em 5em 2.5em 5em",
                        }}
                    >
                        <HStack justify="space-between" color="white">
                            <Text>Follow us on LinkedIn</Text>
                            <Image src={linkedInIcon}></Image>
                        </HStack>
                        <HStack justify={"space-between"} color="white">
                            <Text>Chat to us</Text>
                            <Button
                                bg={"#F8641E"}
                                color="white"
                                fontWeight="500"
                                _hover={{ bg: "rgba(248, 100, 30, 0.7)" }}
                            >
                                Book an appointment
                            </Button>
                        </HStack>
                    </VStack>

                    {/* About Us */}
                    <VStack
                        w={{ base: "100%", lg: "30%" }}
                        m={{
                            base: "4em 0em 3em 0em",
                            lg: "2.5em",
                        }}
                    >
                        <Text
                            fontSize={{ base: "22px", md: "18px" }}
                            color="white"
                            fontWeight="bold"
                        >
                            About Us
                        </Text>
                        <Text color="white">
                            <Link
                                href="betapersona.com"
                                textDecoration="underline"
                            >
                                betapersona.com
                            </Link>{" "}
                            is an online service offered by: Digitalfields
                            Europe Ltd Reg no. 15999199 85 Great Portland
                            Street First Floor London W1W 7LT UNITED KINGDOM
                        </Text>
                    </VStack>

                    {/* Page Navigation */}
                    <VStack
                        w={{ base: "100%", lg: "35%" }}
                        m={{
                            base: "1em 0em 1em 0em",
                            lg: "2.5em 5em 2.5em 5em",
                        }}
                    >
                        <Link to="/">
                            <Button
                                variant="link"
                                fontSize={{ base: "20px", md: "18px" }}
                                color="white"
                                pr={6}
                                m={0}
                            >
                                HomePage
                            </Button>
                        </Link>
                        <Link to="/Managers">
                            <Button
                                variant="link"
                                fontSize={{ base: "20px", md: "18px" }}
                                color="white"
                                pr={6}
                                m={0}
                            >
                                For hiring managers
                            </Button>
                        </Link>
                        <Link to="/HRBP">
                            <Button
                                variant="link"
                                fontSize={{ base: "20px", md: "18px" }}
                                color="white"
                                pr={6}
                                m={0}
                            >
                                For HRBP's
                            </Button>
                        </Link>
                        <Link to="/Recruiters">
                            <Button
                                variant="link"
                                fontSize={{ base: "20px", md: "18px" }}
                                color="white"
                                pr={6}
                                m={0}
                            >
                                For recruiters
                            </Button>
                        </Link>
                        <Link to="/Professionals">
                            <Button
                                variant="link"
                                fontSize={{ base: "20px", md: "18px" }}
                                color="white"
                                pr={6}
                                m={0}
                            >
                                For registered professionals
                            </Button>
                        </Link>
                    </VStack>
                </Stack>
                <Divider
                    borderBottomWidth="0.8px"
                    opacity="1"
                    borderColor="white"
                />
                <Text
                    textAlign="center"
                    color="white"
                    fontSize={{ md: "16px", lg: "14px" }}
                    pt="5"
                >
                    The term "betapersona": could potentially blend the concepts
                    of the "beta" test phase and "persona," referring to the
                    trial state of an individual's projected image or behavior.
                    In this interpretation, a "betapersona" might describe a
                    persona that is still in development or testing—one that is
                    not fully formed, much like software in its beta phase. It
                    could be a way to signify someone who is experimenting with
                    different aspects of their identity, behavior, or role in
                    social or professional settings, trying to find what works
                    before settling on a more definitive "final version" of
                    themselves. (ChatGpt - 4 October 2024)
                </Text>
            </Box>

            <Box bg={"#B3D2D1"} p="3">
                <Image src={logoBlack} alt="BetaPersona Logo" />
            </Box>
        </div>
    );
}
